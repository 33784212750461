<template>
<div>
    <el-form label-width="130px">
        <div class="title">活动1
            <span   class="desc">最早的版本</span>
        </div>
        <div class="activity_block">
            <el-form-item label="保存图片文字">
                <el-input
                    v-model="data_value.activity1_save_image_txt"
                    placeholder="默认：红包码领取">
                </el-input>
            </el-form-item>
            <el-form-item label="扫一扫">
                <el-input
                    v-model="data_value.activity1_scan"
                    placeholder="默认：扫描红包码">
                </el-input>
            </el-form-item>
        </div>
        <div class="title">活动2
            <span   class="desc">小蓝环</span>
        </div>
        <div class="activity_block">
            <el-form-item label="保存图片文字">
                <el-input
                    v-model="data_value.activity2_save_image_txt"
                    placeholder="默认：保存收钱码">
                </el-input>
            </el-form-item>
            <el-form-item label="扫一扫">
                <el-input
                    v-model="data_value.activity2_scan"
                    placeholder="默认：扫描收钱码">
                </el-input>
            </el-form-item>
        </div>
        <div class="title">活动3
            <span   class="desc">小蓝环，仿照厦门的</span>
        </div>

        <div class="activity_block">
            <el-form-item label="保存图片文字">
                <el-input
                    v-model="data_value.activity3_save_image_txt"
                    placeholder="默认：保存收钱码">
                </el-input>
            </el-form-item>
            <el-form-item label="扫一扫">
                <el-input
                    v-model="data_value.activity3_scan"
                    placeholder="默认：扫描收钱码">
                </el-input>
            </el-form-item>
            <el-form-item label="跳转按钮文字">
                <el-input
                    v-model="data_value.activity3_jump_h5_button_txt"
                    placeholder="默认：领收款码">
                </el-input>
            </el-form-item>
            <el-form-item label="跳转链接">
                <el-input
                    v-model="data_value.activity3_jump_h5_path"
                    placeholder="例如：https://baidu.com">
                </el-input>
            </el-form-item>
            <el-form-item label="弹窗-红包金额">
                <el-input-number
                    v-model="data_value.activity3_pop_amount"
                    :step="1"
                    size="medium">
                </el-input-number>
                <span   style="color: #aaa;margin-left: 10px;">默认99，单位元</span>
            </el-form-item>
            <el-form-item label="弹窗-中间描述">
                <el-input
                    type="textarea"
                    v-model="data_value.activity3_pop_description"
                    placeholder="例如默认：恭喜您获得碰一下红包\n最高99元">
                </el-input>
            </el-form-item>
            <el-form-item label="弹窗-底下按钮">
                <el-input
                    v-model="data_value.activity3_pop_bottom_button_txt"
                    placeholder="例如默认：去识别领红包">
                </el-input>
            </el-form-item>
        </div>
    </el-form>
    <div    class="padding"></div>
</div>
</template>

<script>
export default{
    components:{

    },
    props: {
        json_value:{
            type:String ,
            default:""
        }
    },
    data() {
        return {
            data_value:{
                "activity1_save_image_txt":"保存图片",
                "activity2_save_image_txt":"保存图片",
                "activity3_save_image_txt":"保存图片",
                "activity1_scan":"扫码",
                "activity2_scan":"扫码",
                "activity3_scan":"扫码",
                "activity3_jump_h5_button_txt":"领收款码",
                "activity3_jump_h5_path":"",
                "activity3_pop_amount":99,
                "activity3_pop_description":"恭喜您获得碰一下红包\n最高99元",
                "activity3_pop_bottom_button_txt":"去识别领红包",
            },
        }
    },
    methods:{
        // 等待数据不为undefined的时候加载组件内数据
        wait_util_not_undefined(){
            const  intervalTimer = setInterval(
                ()=>{
                    if(this.data_value != undefined){
                        this.init();
                        clearInterval(intervalTimer);
                    }
                },
                1000
            )
        },
        // 加载组件内数据
        init(){
            if(this.json_value) {
                this.data_value = JSON.parse(this.json_value);
            }
        }
    },
    computed:{

    },
    created(){
        this.wait_util_not_undefined();
    },
    watch:{
        //对结果进行解码，上传给父组件
        data_value:{
            deep:true,
            handler(newValue){
                this.$emit("update:json_value",JSON.stringify(newValue));
            }
        }
    }
}
</script>

<style  lang="less" scoped>
.title{
    font-size: 24px;
    color: #333333;
    margin-top: 30px;
    margin-bottom: 10px;
    .desc{
        font-size: 16px;
        color: #999999;
        margin-left: 10px;
    }
}
.activity_block{
    background-color: #FFFFFF;
    padding: 30px ;
    padding-bottom: 10px;
    border-radius: 10px;
    max-width: 850px;
}
.padding{
    height: 100px;
}
</style>