<template>
<div class="div_main">
    <el-upload
        class="upload-demo"
        drag
        action="https://jsonplaceholder.typicode.com/posts/"
        :on-change="handle"
        :auto-upload="false"
        multiple>
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
        <div class="el-upload__tip" slot="tip">只能上传jpg/png文件，且不超过500kb</div>
    </el-upload>
    <button>清除</button>
    <!-- :before-upload="handle"    :on-change="handle"  -->
    <canvas id="canvas" style="display:none;"></canvas>
    <button @click="test">测试</button>
</div>
</template>
<script>
  
import axios from "axios";

export default {
    components:{

    },
    data() {
      return {
        
      };
    },
    watch: {
        
    },
    created() {
        
    },
    methods: {
        handle(file,fileList){
            // console.log("----");
            // for(let  item of fileList){
            //     this.convertToWebP(item.raw);
            // }
            this.convertToWebP(file.raw);
        },
        convertToWebP(fileInput) {
            const reader = new FileReader();
            reader.onload = function (e) {
                const img = new Image();
                img.onload = function () {
                    // Create a canvas for each image
                    const canvas = document.createElement('canvas');
                    const ctx = canvas.getContext('2d');
                    canvas.width = img.width;
                    canvas.height = img.height;
                    ctx.drawImage(img, 0, 0);

                    // Convert canvas content to WebP format
                    canvas.toBlob(function (blob) {
                        // Create a URL for the blob object
                        const url = URL.createObjectURL(blob);

                        // Create a link to download the WebP image
                        const a = document.createElement('a');
                        a.href = url;
                        a.download = fileInput.name.split('.')[0]+'.webp';
                        document.body.appendChild(a);
                        a.click();
                        document.body.removeChild(a);

                        // Clean up the URL object
                        window.URL.revokeObjectURL(url);
                    }, 'image/webp');
                };
                img.src = e.target.result;
            };
            reader.readAsDataURL(fileInput);
        },
        test(){
            axios.get('http://localhost:9000/forTest/test').then(res=>{
                console.log("get res",res);
            });
        }
    },
};
</script>
  
<style lang="less" scoped>

</style>
  