<script>
import store from "@/store";
import axios from "axios";

export default {
    name: "index",
    methods:{
        /**
         * 虽然这个是处理图片上传前触发的事件，但是在这里上传弹窗照片，而不是用他们组件上传
         * @param file 上传的文件
         */
        handleBeforeUpload(file) {
            let imageName ='pop_image.' +this.getFileExtension(file.name);
            let url = store.state.global_base_url.main+'imageManager/store';
            let formData = new FormData();
            formData.append('image',file,imageName);
            formData.append('path','redpacket/swiper');
            formData.append('isOverride',true);
            formData.append('isNeedCompress',true);
            axios.post(url,formData).then(()=>{
                this.$message.success("成功上传文件");
                axios.get(store.state.global_base_url.main+
                    "cdn/freshUrl?url=http://cdn.daliuliang.top/redpacket/swiper/pop_image.gif");
            });
        },
        // 从文件名中获取文件扩展名
        getFileExtension(filename) {
            const parts = filename.split('.');
            return parts.length > 1?parts.pop():'';
        },

    }
}
</script>

<template>
<div    class="">
    <div    class="button_container">
        <el-upload
            action="upload_image_action_path"
            :show-file-list='false'
            :before-upload="handleBeforeUpload"
        >
            <el-button	type="primary">替换弹窗照片</el-button>
        </el-upload>
    </div>
</div>
</template>

<style scoped lang="less">
.button_container{
    display: flex;
    margin-bottom: 20px;
    .el-button{
        margin-right: 20px;
    }
}
</style>