// 该文件用于创建Vuex中最为核心的store
import Vue from 'vue'
// 引入vuex
import Vuex from 'vuex'
// vue使用vuex
Vue.use(Vuex)
//导入图片选择
import image_choose from './modules/image_choose'
                      

const SERVER_URL_BFBZ = 'https://bfbz.cc/api/'
//api路径选择项
	//非本地api路径
    const RED_PACKET_URL ='https://bfbz.vip/thirdparty/' ;	//红包
    const FILM_URL = 'https://tuiliang.top/thirdparty/' ; //电影票和互动 （小菜的项目）
    const PINGAN_LUCARD_URL ='https://daliuliang.top/thirdparty/' ;// 平安银行 - 陆卡汇/泰康保险/安行天下保险 的做信用卡的
    const INVEST_URL = 'https://duoduotuiliang.com/thirdparty/'	//前满屋投资专用，实际指向RED_PACKET_URL
    const DOCKER_TEST_URL = 'https://baomanwu.com/thirdparty/'	//前满屋投资专用，实际指向RED_PACKET_URL
    const TEMP_MIX_URL = 'https://qianmanwu.com/thirdparty/'	//
    const CDN_API_URL = 'https://cdnapi.daliuliang.top/thirdparty/'	//cdn的路径
    // const QSHX_URL = 'https://qshx.ink/thirdparty/'	//以前的贷款的网络路径，现在已经没有用了
//本地api路径
    const LOCAL_URL = 'http://localhost:9000/'	//本地路径
    const LOCAL_IP_URL = 'http://192.168.5.24:9000/'	//本地的ip路径，用来小程序在支付宝的真机调试用的，真机调试需要手机也在电脑的局域网内,才能连的上
//模组
const modules = {
    image_choose
}
// 准备actions—用于响应组件中的动作
const actions = {}
// 准备state—用于存储数据
const state = {
    themeColor : "#0001",
    isThirdParty : false ,
    global_base_url:{
        main:RED_PACKET_URL,
        cdn:LOCAL_IP_URL,
    }
}
// 准备mutations——用于操作数据(state)
const mutations = {
    changeThemeColor(state,val){
        state.themeColor = val;
    }
}
// 准备getters——用于将state中的数据进行加工(不一定要使用)
const getters ={
    themeColor(state){
        return state.themeColor
    }
}

// 创建并暴露stroe
export default new Vuex.Store({
	actions,
	mutations,
	state,
	getters,
    modules
})