<script>
export default {
    name: "popContent",
    props: {
        popContentJson:{
            type:String,
            default:'{}',
        },
    },
    data() {
        return {
            show_type_options:[{
                value: 'default',
                label: '默认_动态100块'
            },{
                value: 'origin',
                label: '之前99元红包那个'
            },{
                value:'blue_ring',
                label: "小兰环_下面有手和波纹"
            }],
            pop_content:{
                is_show:true,
            },
        }
    },
    methods: {
        //根据输入进来的数据 处理
        wait_util_not_undefined(){
            if(this.popContentJson != '{}'){
                this.pop_content = JSON.parse(decodeURI(this.popContentJson)) ;
            }else{
                setTimeout(this.wait_util_not_undefined,1000);
            }
        },
    },
    created(){
        this.wait_util_not_undefined();
    },
    watch: {
        //对结果进行解码，上传给父组件
        pop_content:{
            deep:true,
            handler(newValue){
                this.$emit("update:popContentJson",JSON.stringify(newValue));
            }
        }
    },
}
</script>

<template>
<div class="container">
    <el-switch  v-model="pop_content.is_show"></el-switch>
    <el-select v-model="pop_content.show_type" placeholder="请选择">
        <el-option
            v-for="item in show_type_options"
            :key="item.value"
            :label="item.label"
            :value="item.value">
        </el-option>
    </el-select>
</div>
</template>

<style scoped lang="less">
.el-switch{
    margin-right: 30px;
}
</style>