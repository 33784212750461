<script>
import scan_saved_image
    from "@/components/thirdParty/smallRoutine/applet1/childrenPage/smallroutineConfiguration/scan_saved_image.vue";
import axios from 'axios';
import store from '@/store/index.js';
export default {
    name: "main",
    components: {
        scan_saved_image
    },
    data(){
        return {
            config:{
                scanSavedImageJson:'{}',
            },
        };
    },
    methods: {
        //根据输入进来的数据 处理
        async initiailize_data(){
            axios.get(store.state.global_base_url.main+ "thirdApplet1/getGlobalConfiguration")
                .then((res)=>{
                    this.config = res.data.message;
                });
        },
        /**
         * 保存数据
         */
        save(){
            axios.post(store.state.global_base_url.main+ "thirdApplet1/setGlobalConfiguration",this.config)
                .then((res)=>{
                    this.$message.success("成功保存");
                    this.initiailize_data();
                });
        },
    },
    created() {
        this.initiailize_data();
    },
    computed: {

    }
}
</script>

<template>
<div>
    <el-button  @click="save">保存</el-button>
    <h1>活动</h1>
    <el-divider content-position="left"></el-divider>
        <scan_saved_image   :json_value.sync="config.scanSavedImageJson"></scan_saved_image>
</div>
</template>

<style scoped lang="less">
.button_container{
    display: flex;
    margin-bottom: 20px;
    .el-button{
        margin-right: 20px;
    }
}
</style>