/**
 * 用于在小程序配置对话框中配置小程序底部导航栏项类型的选项
 *
 * <b style="color: red;">一级的 value 值不要与二级的 value 值重复，否则会有 bug</b><br/>
 * 如果要完全避免 bug，请将原先的替换数据项的方法由递归(写递归是为了后面分类层级可以直接增加)修改为二层循环
 * src/components/thirdParty/smallRoutine/applet1/childrenPage/smallroutineConfiguration/navigation_bar.vue 中的
 * navigationTypeChangeHandler 方法
 */
export default [
    {
        label: '之前红包',
        value: 'redpacket_before',
        children: [
            {label: '红包模板', value: 'redpacket', type: 'redpacket', name: '主页', title: '首页'},
            {label: '扭蛋机', value: 'lotteryEgg', type: 'lotteryEgg', name: '扭蛋机', title: '扭蛋机'},
            {label: '轮播图', value: 'swiper', type: 'swiper', name: '首页', title: '首页'},
            {label: '首页', value: 'home', type: 'home', name: '首页', title: '首页'},
            {label: '我的', value: 'my', type: 'my', name: '我的', title: '我的'},
            {label: '签到', value: 'sign', type: 'sign', name: '签到', title: '签到'},
            {label: '扫一扫1', value: 'scan', type: 'scan', name: '扫一扫', title: '扫一扫'},
            {label: '扫一扫2', value: 'scan2', type: 'scan2', name: '扫一扫', title: '扫一扫'},
            {label: '翻一翻', value: 'overturn', type: 'overturn', name: '翻一翻', title: '翻一翻'},
            {label: '转盘', value: 'turnplate', type: 'turnplate', name: '转盘', title: '转盘'},
            {label: '幸运抽奖', value: 'lucky_draw', type: 'lucky_draw', name: '幸运抽奖', title: '幸运抽奖'},
        ]
    },
    {
        label: '小杨红包',
        value: 'redpacket_y3l',
        children: [
            {label: '红包模板1', value: 'Y3LRedpacketOne', type: 'Y3LRedpacketOne', name: '首页', title: '首页'},
            {label: '红包模板2', value: 'Y3LRedpacketTwo', type: 'Y3LRedpacketTwo', name: '首页', title: '首页'},
            {label: '红包模板3', value: 'Y3LRedpacketThree', type: 'Y3LRedpacketThree', name: '首页', title: '首页'},
            {label: '红包模板4', value: 'Y3LRedpacketFour', type: 'Y3LRedpacketFour', name: '首页', title: '首页'},
            {label: '红包模板5', value: 'Y3LRedpacketFive', type: 'Y3LRedpacketFive', name: '首页', title: '首页'},
        ]
    },
    {
        label: '小童红包',
        value: 'redpacket_tcw',
        children: [
            {label: '红包模板1', value: 'TcwOpenRedpacketTemp1', type: 'TcwOpenRedpacketTemp1', name: '首页', title: '首页'},
            {label: '红包模板2', value: 'TcwOpenRedpacketTemp2', type: 'TcwOpenRedpacketTemp2', name: '首页', title: '首页'},
            {label: '红包模板3', value: 'TcwOpenRedpacketTemp3', type: 'TcwOpenRedpacketTemp3', name: '首页', title: '首页'},
            {label: '红包模板4', value: 'TcwOpenRedpacketTemp4', type: 'TcwOpenRedpacketTemp4', name: '首页', title: '首页'},
            {label: '红包模板5', value: 'TcwOpenRedpacketTemp5', type: 'TcwOpenRedpacketTemp5', name: '首页', title: '首页'},
            {label: '红包模板6', value: 'TcwRedpacketTemp1', type: 'TcwRedpacketTemp1', name: '首页', title: '首页'},
            {label: '红包模板7', value: 'TcwRedpacketTemp2', type: 'TcwRedpacketTemp2', name: '首页', title: '首页'},
            {label: '红包模板8', value: 'TcwRedpacketTemp3', type: 'TcwRedpacketTemp3', name: '首页', title: '首页'},
            {label: '红包模板9', value: 'TcwRedpacketTemp4', type: 'TcwRedpacketTemp4', name: '首页', title: '首页'},
            {label: '红包模板10', value: 'TcwRedpacketTemp5', type: 'TcwRedpacketTemp5', name: '首页', title: '首页'},
            {label: '红包模板11', value: 'TcwRedpacketTemp6', type: 'TcwRedpacketTemp6', name: '首页', title: '首页'},
            {label: '红包模板12', value: 'TcwRedpacketTemp7', type: 'TcwRedpacketTemp7', name: '首页', title: '首页'},
            {label: '红包模板13', value: 'TcwRedpacketTemp8', type: 'TcwRedpacketTemp8', name: '首页', title: '首页'},
            {label: '红包模板14', value: 'TcwRedpacketTemp9', type: 'TcwRedpacketTemp9', name: '首页', title: '首页'},
            {label: '红包模板15', value: 'TcwRedpacketTemp10', type: 'TcwRedpacketTemp10', name: '首页', title: '首页'},
            {label: '红包模板16', value: 'TcwRedpacketTemp11', type: 'TcwRedpacketTemp11', name: '首页', title: '首页'},
            {label: '红包模板17', value: 'TcwRedpacketTemp12', type: 'TcwRedpacketTemp12', name: '首页', title: '首页'},
            {label: '红包模板18', value: 'TcwRedpacketTemp13', type: 'TcwRedpacketTemp13', name: '首页', title: '首页'},
            {label: '红包模板19', value: 'TcwRedpacketTemp14', type: 'TcwRedpacketTemp14', name: '首页', title: '首页'},
            {label: '红包模板20', value: 'TcwRedpacketTemp15', type: 'TcwRedpacketTemp15', name: '首页', title: '首页'},
            {label: '红包模板21', value: 'TcwRedpacketTemp16', type: 'TcwRedpacketTemp16', name: '首页', title: '首页'},
            // {label: '红包模板22', value: 'TcwRedpacketTemp17', type: 'TcwRedpacketTemp17', name: '首页', title: '首页'},
        ]
    },
    {
        label: '商城',
        value: 'mall_first',
        children: [
            {label: '小商城', value: 'smallMall', type: 'smallMall', name: '商城', title: '商城'},
            {label: '商城1', value: 'mall', type: 'mall', name: '首页', title: '首页'},
            {label: '商城2', value: 'TcwMallTemp1', type: 'TcwMallTemp1', name: '首页', title: '首页'},
            {label: '商城3', value: 'TcwMallTemp2', type: 'TcwMallTemp2', name: '首页', title: '首页'},
            // 由于商城模板4与商城模板2太像(原来布局为瀑布流，但是图片高度一样时与模板2就太像了)，使用新的模板10替代
            // {label: '商城4', value: 'TcwMallTemp3', type: 'TcwMallTemp3', name: '首页', title: '首页'},
            {label: '商城4', value: 'TcwMallTemp10', type: 'TcwMallTemp10', name: '首页', title: '首页'},
            {label: '商城5', value: 'TcwMallTemp4', type: 'TcwMallTemp4', name: '首页', title: '首页'},
            {label: '商城6', value: 'TcwMallTemp5', type: 'TcwMallTemp5', name: '首页', title: '首页'},
            {label: '商城7', value: 'TcwMallTemp6', type: 'TcwMallTemp6', name: '首页', title: '首页'},
            {label: '商城8', value: 'TcwMallTemp7', type: 'TcwMallTemp7', name: '首页', title: '首页'},
            {label: '商城9', value: 'TcwMallTemp8', type: 'TcwMallTemp8', name: '首页', title: '首页'},
            // 由于商城模板10与商城模板2和模板4太像(商品数量少的时候与模板2和模板4太像)，使用新的模板11替代
            // {label: '商城10', value: 'TcwMallTemp9', type: 'TcwMallTemp9', name: '首页', title: '首页'},
            // 看着怪怪的(不像商城,主要是因为那个模糊效果,所以使用新模板替换)
            // {label: '商城10', value: 'TcwMallTemp11', type: 'TcwMallTemp11', name: '首页', title: '首页'},
            {label: '商城10', value: 'TcwMallTemp12', type: 'TcwMallTemp12', name: '首页', title: '首页'},
        ]
    },
    {
        label: '活动',
        value: 'activity',
        children: [
            {label: '活动1_扫一扫', value: 'scan_saved_image_1', type: 'scan_saved_image_1', name: '首页', title: '首页'},
            {label: '活动2_小蓝环', value: 'scan_saved_image_2', type: 'scan_saved_image_2', name: '首页', title: '首页'},
            {label: '活动3_小蓝环_仿厦门', value: 'scan_saved_image_3', type: 'scan_saved_image_3', name: '首页', title: '首页'},
        ]
    },
    {
        label: '工具',
        value: 'tools_first',
        children: [
            {label: '客服中心', value: 'customerService', type: 'customerService', name: '客服', title: '客服中心'},
            {label: '记账本', value: 'accountBook', type: 'accountBook', name: '主页', title: '首页'},
            {label: '花语', value: 'flowerLanguage', type: 'flowerLanguage', name: '花语', title: '花语'},
            {label: '打车', value: 'taking_car', type: 'taking_car', name: '打车', title: '打车'},
            {label: '星座', value: 'constellation', type: 'constellation', name: '星座', title: '星座'},
            {label: '性格测试', value: 'personality', type: 'personality', name: '性格测试', title: '性格测试'},
            {label: '之前成语模板', value: 'idioms', type: 'idioms', name: '成语', title: '成语'},
            {label: '小童成语模板1', value: 'TcwIdiomTemp1', type: 'TcwIdiomTemp1', name: '成语', title: '成语'},
        ]
    },
    {
        label: '计算器模板',
        value: 'calculator_template',
        children: [
            {label: '计算器1', value: 'TcwCalculator', type: 'TcwCalculator', name: '首页', title: '首页'},
            {label: '计算器2', value: 'TcwCalculator2', type: 'TcwCalculator2', name: '首页', title: '首页'},
            {label: '计算器3', value: 'TcwCalculator3', type: 'TcwCalculator3', name: '首页', title: '首页'},
        ]
    },
    {
        label: '信用卡模板',
        value: 'creditCard_template',
        children: [
            {label: '信用卡1', value: 'credit_template_1', type: 'credit_template_1',name: '首页', title: '首页'},
            {label: '信用卡2', value: 'credit_template_2', type: 'credit_template_2',name: '首页', title: '首页'},
            {label: '信用卡3', value: 'credit_template_3', type: 'credit_template_3',name: '首页', title: '首页'},
            {label: '信用卡4', value: 'credit_template_4', type: 'credit_template_4',name: '首页', title: '首页'},
            {label: '信用卡5', value: 'credit_template_5', type: 'credit_template_5',name: '首页', title: '首页'},
            {label: '信用卡6', value: 'credit_template_6', type: 'credit_template_6',name: '首页', title: '首页'},
            {label: '信用卡7', value: 'credit_template_7', type: 'credit_template_7',name: '首页', title: '首页'},
            {label: '信用卡8', value: 'credit_template_8', type: 'credit_template_8',name: '首页', title: '首页'},
            {label: '信用卡9', value: 'credit_template_9', type: 'credit_template_9',name: '首页', title: '首页'},
            {label: '信用卡10', value: 'TcwCreditTemp1', type: 'TcwCreditTemp1',name: '首页', title: '首页'},
        ]
    },
    {
        label:'电影',
        value:'film_template',
        children: [
            {label: '电影票1', value: 'film_template_1', type: 'film_template_1',name: '首页', title: '首页'},
            {label: '电影票2', value: 'film_template_2', type: 'film_template_2',name: '首页', title: '首页'},
            {label: '电影票3', value: 'film_template_3', type: 'film_template_3',name: '首页', title: '首页'},
            {label: '电影票4', value: 'film_template_4', type: 'film_template_4',name: '首页', title: '首页'},
            {label: '电影票5', value: 'film_template_5', type: 'film_template_5',name: '首页', title: '首页'},
        ]
    },
    {
        label:'取名算命',
        value:'name_fortune',
        children: [
            {label: '取名1', value: 'get_name_1', type: 'get_name_1',name: '首页', title: '首页'},
            {label: '取名2', value: 'get_name_2', type: 'get_name_2',name: '首页', title: '首页'},
        ]
    },


]

// 原先的配置小程序底部导航栏项类型的选项列表
// navigation_type_options:[
//     {label:'红包模板',type:'redpacket',name:"主页",title:'首页'},
//     {label:'客服中心',type:'customerService',name:'客服',title:'客服中心'},
//     {label:'记账本',type:'accountBook',name:'主页',title:'首页'},
//     {label:'扭蛋机',type:'lotteryEgg',name:'扭蛋机',title:'扭蛋机'},
//     {label:'小商城',type:'smallMall',name:'商城',title:'商城'},
//     {label:'轮播图',type:'swiper',name:'首页',title:'首页'},
//     {label:'商城',type:'mall',name:'首页',title:'首页'},
//     {label:'首页',type:'home',name:'首页',title:'首页'},
//     {label:'我的',type:'my',name:'我的',title:'我的'},
//     {label:'签到',type:'sign',name:'签到',title:'签到'},
//     {label:'花语',type:'flowerLanguage',name:'花语',title:'花语'},
//     {label:'扫一扫1',type:'scan',name:'扫一扫',title:'扫一扫'},
//     {label:'扫一扫2',type:'scan2',name:'扫一扫',title:'扫一扫'},
//     {label:'翻一翻',type:'overturn',name:'翻一翻',title:'翻一翻'},
//     {label:'转盘',type:'turnplate',name:'转盘',title:'转盘'},
//     {label:'幸运抽奖',type:'lucky_draw',name:'幸运抽奖',title:'幸运抽奖'},
//     {label:'打车',type:'taking_car',name:'打车',title:'打车'},
//     {label:'星座',type:'constellation',name:'星座',title:'星座'},
//     {label:'性格测试',type:'personality',name:'性格测试',title:'性格测试'},
//     {label:'成语',type:'idioms',name:'成语',title:'成语'},
// ]