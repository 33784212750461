//该类是用来做图片选择的
//如果想要了解详情 请搜索image_choose.vue文件
//image_choose.vue是关于图片的工具
import axios from "axios";
import store from ".."; 
export default {
    namespaced:true,
    state:{
        /**
         * 注意，这是一个映射hash
         * prefix_path （key）指的是图片文件的文件夹路径，也就是图片文件的前缀
         * image_paths （value）指的是在这个文件夹路径前缀下的 图片文件 的网络路径
         * 例如 在后端文件中存储了一个文件 redpacket/small_mall_1/love.png
         * 那么prefix_path 就是 redpacket/small_mall_1 （这里前后添加/都一样）
         * image_paths 就是 ['http://localhost:9000/java_file/webapp/wang/redpacket/small_mall_1/love.png']
         */
        prefix_path_Map_image_paths:{

        }
    },
    mutations:{
        change_prefix_path_Map_image_paths(state,payload){
            state.prefix_path_Map_image_paths = {...state.prefix_path_Map_image_paths,...payload}
        }
    },
    actions:{
        /**
         * 该函数用于添加prefix_path_Map_image_paths
         * @param commit    用于提交mutations
         * @param state     用于获取state中数据
         * @param prefix_path   用于指定文件夹前缀，为 String 类型
         * @returns {Promise<void>} 无返回值
         */
        async    prefix_path_Map_image_paths_add({commit,state},prefix_path){
            if(state.prefix_path_Map_image_paths[prefix_path]==undefined){  //如果没有添加进来
                let repsonse_promise = async()=>{
                    return     (await axios.get(store.state.global_base_url.main+"imageManager/getList?path="+prefix_path)).data.message;
                }
                let data  = {} ;
                data[prefix_path] = "";
                commit("change_prefix_path_Map_image_paths",data);
                data = {} ;
                data[prefix_path] = await repsonse_promise();
                commit("change_prefix_path_Map_image_paths",data);
            }
        },
        //更新数据
        async    prefix_path_Map_image_paths_reset({commit,state},prefix_path){
            let repsonse_promise = async()=>{
                return     (await axios.get(store.state.global_base_url.main+"imageManager/getList?path="+prefix_path)).data.message;
            }
            let    data = {};
            data[prefix_path] = await repsonse_promise();
            commit("change_prefix_path_Map_image_paths",data);
        }
    }
}